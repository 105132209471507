/**
 * Created by jerry on 2020/04/15.
 * 套餐卡销售提成设置经手人 api
 */
import * as API from '@/api/index'
export default {
    // 获取套餐卡销售组织单位提成方案列表
    getSalePackageCardCommissionScheme: params => {
        return API.POST('api/salePackageCardCommissionScheme/list', params)
    },
    // 创建套餐卡销售组织单位提成方案
    createSalePackageCardCommissionScheme: params => {
        return API.POST('api/salePackageCardCommissionScheme/create', params)
    },
    // 删除套餐卡销售提成方案
    deleteSalePackageCardCommissionScheme: params => {
        return API.POST('api/salePackageCardCommissionScheme/delete', params)
    },
    // 获取套餐卡销售分类提佣方案
    getSalePackageCardCategoryCommission: params => {
        return API.POST('api/salePackageCardCategoryCommission/all', params)
    },
    // 保存套餐卡销售分类提成方案
    updateSalePackageCardCategoryCommission: params => {
        return API.POST('api/salePackageCardCategoryCommission/update', params)
    },
    // 获取套餐卡销售分类经手人提佣方案
    getSalePackageCardCategoryHandlerCommission: params => {
        return API.POST('api/salePackageCardCategoryHandlerCommission/all', params)
    },
    // 保存套餐卡销售分类经手人提成方案
    updateSalePackageCardCategoryHandlerCommission: params => {
        return API.POST('api/salePackageCardCategoryHandlerCommission/update', params)
    },
    // 获取分类下的套餐卡提佣方案
    getSalePackageCardCommission: params => {
        return API.POST('api/salePackageCardCommission/all', params)
    },
    // 保存套餐卡销售提成方案
    updateSalePackageCardCommission: params => {
        return API.POST('api/salePackageCardCommission/update', params)
    },
    // 获取套餐卡下经手人提佣方案
    getSalePackageCardHandlerCommission: params => {
        return API.POST('api/salePackageCardHandlerCommission/all', params)
    },
    // 保存套餐卡下经手人提成方案
    updateSalePackageCardHandlerCommission: params => {
        return API.POST('api/salePackageCardHandlerCommission/update', params)
    },
    // 获取所有套餐卡经手人提佣方案
    getSalePackageCardSchemeHandlerCommission: params => {
        return API.POST('api/salePackageCardSchemeHandlerCommission/all', params)
    },
    // 保存所有套餐卡经手人提成方案
    updateSalePackageCardSchemeHandlerCommission: params => {
        return API.POST('api/salePackageCardSchemeHandlerCommission/update', params)
    },
}